import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import Modal from 'Components/common/modal';

import { trackEvent } from 'Utils/analytics';
import PlatformUtils from 'Utils/platformUtils';
import { getRandomImage } from 'Utils/reviewUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';

import type { TProps } from './interface';
import { ModalContainer, ModalStoryContainer } from './style';

const StoryCarouselDesktop = dynamic(
	() =>
		import(
			/* webpackChunkName: 'Story Carousel Desktop' */ 'Components/desktop/storyCarousel'
		),
);
const StoryCarouselMobile = dynamic(
	() =>
		import(
			/* webpackChunkName: 'Story Carousel Mobile' */ 'Components/mobile/storyCarousel'
		),
);

export default function ReviewStoryWrapper({
	nonCustomerName,
	rating,
	reviewerImageUrl = '',
	content,
	reviewTime,
	reviewMedias,
	reviewStoryRef,
	nonCustomerCountryCode,
	nonCustomerCountryName,
}: TProps) {
	const [showStoryCarousel, setShowStoryCarousel] = useState(false);
	const [storyIndex, setStoryIndex] = useState(0);
	const isDesktop = PlatformUtils.isDesktop();

	const storyPreviewed = (index: number, rating?: number) => {
		setShowStoryCarousel(true);
		setStoryIndex(index);
		if (rating) {
			trackEvent({
				eventName: ANALYTICS_EVENTS.REVIEW_STORY.OPENED,
				[ANALYTICS_PROPERTIES.RATING]: rating,
			});
			trackEvent({
				eventName: ANALYTICS_EVENTS.REVIEW_STORY.PREVIEWED,
				[ANALYTICS_PROPERTIES.RATING]: rating,
			});
		}
	};

	useEffect(() => {
		if (reviewStoryRef)
			reviewStoryRef.current = {
				showStory: storyPreviewed,
			};
	}, []);

	const hideCarousel = () => {
		setShowStoryCarousel(false);
	};

	if (!showStoryCarousel || !reviewMedias || !nonCustomerName) return null;

	return (
		<ModalContainer>
			<Modal
				open={showStoryCarousel}
				containerClassName={'story-modal-container'}
				contentClassName={'core-modal-content'}
				onRequestClose={() => {
					setShowStoryCarousel(false);
					trackEvent({
						eventName: ANALYTICS_EVENTS.REVIEW_STORY.CLOSED,
					});
				}}
				// @ts-expect-error TS(2769): No overload matches this call.
				dimensionsStyle={{
					marginTop: '10rem',
					marginBottom: '10rem',
				}}
			>
				<ModalStoryContainer>
					{isDesktop ? (
						<StoryCarouselDesktop
							storyIndex={storyIndex}
							reviewMedias={reviewMedias}
							content={content}
							rating={rating}
							customerName={nonCustomerName}
							reviewerImgUrl={
								reviewerImageUrl ??
								getRandomImage(nonCustomerName || '')
							}
							reviewTime={reviewTime}
							hideCarousel={hideCarousel}
							customerCountryName={nonCustomerCountryName}
							customerCountryCode={nonCustomerCountryCode}
						/>
					) : (
						<StoryCarouselMobile
							storyIndex={storyIndex}
							reviewMedias={reviewMedias}
							content={content}
							rating={rating}
							customerName={nonCustomerName}
							reviewerImgUrl={
								reviewerImageUrl ??
								getRandomImage(nonCustomerName || '')
							}
							reviewTime={reviewTime}
							hideCarousel={hideCarousel}
							customerCountryName={nonCustomerCountryName}
							customerCountryCode={nonCustomerCountryCode}
						/>
					)}
				</ModalStoryContainer>
			</Modal>
		</ModalContainer>
	);
}
