/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import colors from 'Static/typography/colors';

export const ModalStoryContainer = styled.div`
	.modal-story-carousel {
		max-width: 49.5rem;
		margin: 1.25rem 0 2rem 0;
		width: 100%;
		position: relative;

		.modal-stoty-container {
			width: 27.375rem;
			height: 43.75rem;
		}

		.arrow-button-list {
			transform: none;
			width: 100%;
		}

		.prev-button-wrapper,
		.next-button-wrapper {
			top: 21.875rem;
			right: -3.125rem;
			padding: 6.25rem;
		}

		.prev-button-wrapper {
			left: -3.125rem;
		}
	}
`;

export const ModalContainer = styled.div`
	.story-modal-container {
		background: ${colors.BLACK};
		z-index: 200001;
		padding: 0;
		margin: 0;
	}

	.core-modal-content {
		background: none;
		min-width: auto;
		min-height: 43.75rem;
	}
`;
